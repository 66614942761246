<template>
  <div class="cart">
    <div class="cart-review-title">
      <h1 class="page-title">Review your items</h1>

      <div class="cart-steps-container">
        <div class="cart-step active">
          <div class="circle"></div>
          <p>Check items</p>
        </div>
        <div class="cart-step">
          <div class="circle"></div>
          <p>Payment method</p>
        </div>
        <div class="cart-step">
          <div class="circle"></div>
          <p>Pay</p>
        </div>
        <div class="cart-step">
          <div class="circle"></div>
          <p>Collect ITEMS</p>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-12 col-md-8">
        <div class="product-listing">
          <product-cart-item
            :product="product"
            v-for="product in cart"
            :key="product.id"
          ></product-cart-item>
        </div>
      </div>
      <div class="col-12 col-md-4">
        <div class="cart-sidebar">
          <div class="sidebar-widget">
            <b-overlay
              :show="busy"
              rounded
              opacity="0.6"
              spinner-small
              spinner-variant="primary"
              class="d-inline-block"
            >
              <b-btn
                variant="primary"
                block
                @click="goToPayment()"
                :disabled="
                  busy ||
                  cart.length == 0 ||
                  verifyTicketsName() ||
                  verifyShutter()
                "
              >
                Go to payment method
              </b-btn>
            </b-overlay>

            <div class="sidebar-total">
              <p>
                Order Total
                <span>
                  {{ cartTotal | currency }}
                </span>
              </p>
            </div>
          </div>

          <div
            class="sidebar-widget product-info"
            v-for="product in cart"
            :key="product.id"
          >
            <ul class="list-unstyled">
              <li>
                <p>{{ product.quantity }}x {{ product.name }}</p>
                <span>{{ (product.price * product.quantity) | currency }}</span>
              </li>
              <li
                class="info-small"
                v-for="ticket in product.tickets"
                :key="ticket.id"
              >
                <p>{{ ticket.ticket_name }}</p>
                <span v-if="false">COVID test € 10,00</span>
              </li>
              <li class="info-small" v-if="product.vat > 0">
                <p>Tax {{ product.vat }}%</p>
                <span>€ {{ new Intl.NumberFormat('de-DE').format(product.price_vat * product.quantity) }}</span>
              </li>
            </ul>
          </div>

          <b-btn class="mt-4" size="sm" style="margin-top: 4rem; font-size: 1rem; color: #c1c1c1;" variant="link" @click.stop="clearCart">Clear cart</b-btn>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Toast from "@/mixin/toast";
import ProductService from "@/services/product.service";
import ProductCartItem from "@/components/ProductCartItem.vue";

export default {
  name: "CartReview",
  metaInfo: {
    title: "Review your Items",
  },
  mixins: [Toast],
  components: {
    ProductCartItem,
  },
  computed: {
    cart() {
      return this.$store.getters.cart;
    },
    coupon() {
      return this.$store.getters.coupon || {};
    },
    cartTotal: {
      cache: false,
      get() {
        return this.$store.getters.cart.reduce(
          (prev, cur) => prev + (cur.total + cur.price_vat * cur.quantity),
          0
        );
      },
    },
  },
  data() {
    return {
      products: [],
      busy: false,
      hasProductMaxSold: false,
    };
  },
  methods: {
    async goToPayment() {
      this.busy = true;
      this.hasProductMaxSold = false;
      const checkProducts = this.cart.map((item) => ({
        product_id: item.id,
        quantity: item.tickets.length,
      }));
      await ProductService.verifyMaxProductsSold(checkProducts, this.coupon.id).then(
        (result) => {
          if (result.data.status) {
            this.hasProductMaxSold = true;
            this.showToast("danger", "Error", result.data.errors[0]);
          }
        },
        (error) => {
          this.busy = false;
          console.error(error);
        }
      );
      this.busy = false;
      if (!this.hasProductMaxSold) {
        this.$router.push(`/cart/payment`);
      }
    },
    verifyTicketsName() {
      return this.cart
        .flatMap((cart) => cart.tickets.map((t) => t.ticket_name === ""))
        .includes(true);
    },
    verifyShutter() {
      return this.cart
        .filter((c) => c.product_type === "product_type_shuttle")
        .flatMap((cart) =>
          cart.tickets.map(
            (t) => t.shuttle_arrival == "" || t.day == "" || t.hour == ""
          )
        )
        .includes(true);
    },
    clearCart() {
      if(confirm('Are you sure that you want to remove all items on your cart and start over?')) {
        this.$store.commit("updateCart", []);
        setTimeout(() => {
          this.$router.push(`/`);
        }, 1000);
      }
    }
  },
};
</script>

<style lang="scss" scoped>
.cart-review-title {
  .page-title {
    margin: 6rem 0 3rem;
    font-size: 76px;
    font-weight: 700;
    text-align: center;

    @media screen and (max-width: 770px) {
      margin-top: 15px;

      line-height: 1.0;
    }
  }
}
</style>
